import { Component, Match, Show, Switch, createSignal } from "solid-js";
import styles from "./snackbar.module.scss";
import { classNames } from "../utils/etc";
import { PhosphorIcon } from "../widgets/icons";
import { APIError } from "../utils/fetch";

export const [snackbar, _setSnackbar] = createSignal<Component | undefined>();
const [isSnackbarVisible, setIsSnackbarVisible] = createSignal(false);

export type SnackBarLevel = "info" | "error" | "warning" | "success";

export function showSnackBar({
  level,
  message,
}: {
  level: SnackBarLevel;
  message: string;
}) {
  _setSnackbar(() => () => <SnackBar level={level} message={message} />);
  setIsSnackbarVisible(true);
  setTimeout(() => setIsSnackbarVisible(false), 3000);
}

function _getStyles(level: SnackBarLevel) {
  switch (level) {
    case "info":
      return styles.info;
    case "error":
      return styles.error;
    case "warning":
      return styles.warning;
    case "success":
      return styles.success;
  }
}

function SnackBar(props: { level: SnackBarLevel; message: string }) {
  return (
    <div
      class={classNames(
        styles.snackbar,
        _getStyles(props.level),
        isSnackbarVisible() ? styles.visible : styles.hidden
      )}
    >
      <Switch>
        <Match when={props.level == "info"}>
          <PhosphorIcon name="info" class={`${styles.icon} pt-[2px]`} />
        </Match>
        <Match when={props.level == "error"}>
          <PhosphorIcon name="x-circle" class={styles.icon} />
        </Match>
        <Match when={props.level == "warning"}>
          <PhosphorIcon name="warning" class={styles.icon} />
        </Match>
        <Match when={props.level == "success"}>
          <PhosphorIcon name="check" class={styles.icon} />
        </Match>
      </Switch>
      <div class={styles.message}>{props.message}</div>
    </div>
  );
}

export function SnackbarHost() {
  return <Show when={snackbar()}>{snackbar()}</Show>;
}

export async function snackApiCallWrapper<T>(call: Promise<T>): Promise<T> {
  try {
    return await call;
  } catch (e) {
    const error = e as APIError;
    showSnackBar({ level: "error", message: error.message });
    throw e;
  }
}
